<template>
  <div class="box">
    <!-- 面包屑 -->
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/orgManage' }"
        >机构管理</el-breadcrumb-item
      >
      <el-breadcrumb-item>详情页</el-breadcrumb-item>
    </el-breadcrumb>
    <!-- 基础信息 -->
    <div class="header">
      <!-- 标题 -->
      <div class="biaoti">基础信息</div>
      <!-- 数据 -->
      <div class="messageOne">
        <span class="spanone">机构名称</span>
        <span class="spantwo">{{ orgName }}</span>
        <span class="spanthree">机构类别</span>
        <span class="spanfour">{{ type }}</span>
        <span class="spanfive">统一社会信用代码</span>
        <span class="spansix">{{ uscCode }}</span>
      </div>
      <div class="messageTwo">
        <span class="spanone">机构类型</span>
        <span class="spantwo">{{ orgType }}</span>
        <span class="spanthree">用户等级</span>
        <span class="spanfoura">{{ memberLevel }}</span>
        <span class="spanfive">管理账号</span>
        <span class="spansix">{{ loginName }}</span>
      </div>
      <div class="messageThree">
        <span class="spanone">手机号</span>
        <span class="spantwo">{{ phone }}</span>
        <span class="spanthreea">充值金额(元)</span>
        <span class="spanfoura">{{ money }}</span>
        <span class="spanfivea">开通时间</span>
        <span class="spansix">{{ startTime }}</span>
      </div>
      <div class="messageFour">
        <span class="spanone">结束时间</span>
        <span class="spantwo">{{ endTime }}</span>
        <span class="spanthree">账号状态</span>
        <span v-show="status == '正常'" class="spanfour"
          ><span v-show="status == '正常'" class="green">●</span
          >{{ status }}</span
        >
        <span v-show="status == '被驳回'" class="spanfour"
          ><span v-show="status == '被驳回'" class="bohui">●</span
          >{{ status }}</span
        >
        <span v-show="status == '到期'" class="spanfour"
          ><span v-show="status == '到期'" class="daoqi">●</span
          >{{ status }}</span
        >
        <span v-show="status == '冻结'" class="spanfour"
          ><span v-show="status == '冻结'" class="dongjie">●</span
          >{{ status }}</span
        >
        <span v-show="status == '待审核'" class="spanfour"
          ><span v-show="status == '待审核'" class="daishenhe">●</span
          >{{ status }}</span
        >
      </div>
    </div>
    <!-- 用户权益 -->
    <div class="user">
      <!-- 标题 -->
      <div class="biaoti">用户权益</div>
      <!-- 数据 -->
      <div class="userone">
        <span class="spanone">功能</span>
        <span class="spantwo">二手商用车快估</span>
        <span class="spanthree">车型车价</span>
        <span class="spanfour">登记状态</span>
        <span class="spanfive">行业主体</span>
        <span class="spansix">关注主体</span>
        <span class="spanseven">关注登记</span>
        <span class="spaneight">销量数据</span>
        <span class="spannine">子账号(个)</span>
        <span class="spanzero">金币(个)</span>
      </div>
      <div class="usertwo">
        <span class="spanone">权益值</span>
        <span class="spantwo">{{ yquickValuationy }}</span>
        <span class="spanthree">{{ ycarPricey }}</span>
        <span class="spanfour">{{ yregistery }}</span>
        <span class="spanfive">{{ ysubjecty }}</span>
        <span class="spansix">{{ yfollowSubjecty }}</span>
        <span class="spanseven">{{ yfollowRegister }}</span>
        <span class="spaneight">全部数据+报告</span>
        <span class="spannine">{{ childNuma }}</span>
        <span class="spanzero">{{ coina }}</span>
      </div>
      <div class="usertwo">
        <span class="spanone">剩余</span>
        <span class="spantwo">{{ yquickValuation }}</span>
        <span class="spanthree">{{ ycarPrice }}</span>
        <span class="spanfour">{{ yregister }}</span>
        <span class="spanfive">{{ ysubject }}</span>
        <span class="spansix">{{ yfollowSubject }}</span>
        <span class="spanseven">{{ yfollowRegister }}</span>
        <span class="spaneight">全部数据+报告</span>
        <span class="spannine">{{ childNum }}</span>
        <span class="spanzero">{{ coin }}</span>
      </div>
      <div class="usertwo">
        <span class="spanone">月次数上限</span>
        <span class="spantwo">{{ mquickValuationm }}</span>
        <span class="spanthree">{{ mcarPricem }}</span>
        <span class="spanfour">{{ mregisterm }}</span>
        <span class="spanfive">{{ msubjectm }}</span>
        <span class="spansix">{{ mfollowSubjectm }}</span>
        <span class="spanseven">{{ mfollowRegisterm }}</span>
        <span class="spaneight">/</span>
        <span class="spannine">/</span>
        <span class="spanzero">/</span>
      </div>
      <div class="usertwo">
        <span class="spanone">本月次数剩余</span>
        <span class="spantwo">{{ mquickValuation }}</span>
        <span class="spanthree">{{ mcarPrice }}</span>
        <span class="spanfour">{{ mregister }}</span>
        <span class="spanfive">{{ msubject }}</span>
        <span class="spansix">{{ mfollowSubject }}</span>
        <span class="spanseven">{{ mfollowRegister }}</span>
        <span class="spaneight">/</span>
        <span class="spannine">/</span>
        <span class="spanzero">/</span>
      </div>
    </div>
    <!-- 附件 -->
    <div class="accessory">
      <!-- 标题 -->
      <div class="biaoti">附件</div>
      <!-- 数据 -->
      <div class="none">
        <span class="one">合同编号</span>
        <!-- <span class="two">012345678901234567.jpg</span> -->
        <!-- <span class="two">{{dealPay}}</span> -->

        <span class="two">
          <a :href="contract" target="_blank">{{ orgName }}.合同编号</a></span
        >

        <span class="three">支付凭证</span>
        <span class="four"
          ><a :href="dealPay" target="_blank">{{ orgName }}.支付凭证</a></span
        >
        <span class="five">营业执照</span>
        <span class="six"
          ><a :href="businessLicense" target="_blank"
            >{{ orgName }}.营业执照</a
          ></span
        >
      </div>
      <div class="ntwo">
        <span class="one">注册人</span>
        <span class="two">{{ createUser }}</span>
        <span class="three">注册时间</span>
        <span class="four">{{ createTime }}</span>
      </div>
      <div class="ntwo">
        <span class="one">审核人</span>
        <span class="two">{{ updateUser }}</span>
        <span class="three">审核时间</span>
        <span class="four">{{ updateTime }}</span>
      </div>
    </div>
    <!-- 操作记录 -->
    <div class="operating">
      <!-- 标题 -->
      <div class="biaoti">操作记录</div>
      <!-- 表格展示 -->
      <div class="table" style="margin-top: 20px">
        <el-table
          :data="list"
          border
          style="width: 100%"
          :header-cell-style="{
            background: '#BFDFF7',
            color: '#606266',
            fontSize: '12px !important',
            border: 'none',
          }"
          :row-style="{ fontSize: '12px !important', border: 'none' }"
        >
          <el-table-column
            type="index"
            align="center"
            label="序号"
            width="120"
          ></el-table-column>
          <!-- <el-table-column prop="orgName" align="center" label="操作编号" width="150"></el-table-column> -->
          <el-table-column
            prop="conditions"
            align="center"
            label="编辑记录"
            width="159"
          ></el-table-column>
          <el-table-column
            prop="userName"
            align="center"
            label="操作人"
            width="140"
          ></el-table-column>
          <el-table-column
            prop="phone"
            align="center"
            label="操作账号"
            width="220"
          ></el-table-column>
          <el-table-column
            prop="createTime"
            align="center"
            label="操作时间"
            width="220"
          ></el-table-column>
          <el-table-column prop="coin" align="center" label="操作" width="140">
            <el-button type="text" @click="kaifazhong = true">查看</el-button>
          </el-table-column>
        </el-table>
        <!-- 分页器 -->
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="listFrom.pageNum"
          :page-sizes="[10, 15, 20, 25]"
          :page-size="listFrom.pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total"
        ></el-pagination>
      </div>
      <div class="btn" style="float: right">
        <el-button
          v-show="!ButtonHide"
          v-if="
            status == '到期' ||
            status == '冻结' ||
            status == '被驳回' ||
            status == '正常' ||
            status == '待审核'
          "
          type="info"
          @click="back"
          style="width: 115px; height: 34px; margin-top: 50px"
          >返回</el-button
        >
        <el-button
          v-show="ButtonHide"
          v-if="status == '到期'"
          style="width: 115px; height: 34px; margin-top: 50px"
          @click="unbundle"
          >账号解绑</el-button
        >
        <el-button
          v-show="ButtonHide"
          v-if="status == '正常' || status == '到期'"
          type="primary"
          style="width: 115px; height: 34px; margin-top: 50px"
          @click="gold"
          >金币充值</el-button
        >
        <el-button
          v-show="ButtonHide"
          v-if="status == '冻结'"
          type="primary"
          style="width: 115px; height: 34px; margin-top: 50px"
          @click="unfree"
          >解冻</el-button
        >
        <el-button
          v-show="ButtonHide"
          v-if="status == '正常'"
          style="width: 115px; height: 34px; margin-top: 50px"
          @click="free"
          >冻结</el-button
        >
        <el-button
          style="width: 115px; height: 34px; margin-top: 50px"
          v-show="ButtonHide"
          v-if="status == '正常'"
          @click="equity"
          >权益编辑</el-button
        >
        <el-button
          style="width: 115px; height: 34px; margin-top: 50px"
          v-show="ButtonHide"
          v-if="status == '待审核'"
          @click="turn"
          >驳回</el-button
        >
        <el-button
          v-show="ButtonHide"
          v-if="status == '待审核'"
          type="primary"
          style="width: 115px; height: 34px; margin-top: 50px"
          @click="pass"
          >通过</el-button
        >
        <el-button
          v-show="ButtonHide"
          v-if="status == '正常'"
          style="width: 115px; height: 34px; margin-top: 50px"
          @click="number"
          >账号更换</el-button
        >
        <el-button
          v-show="ButtonHide"
          v-if="status == '被驳回'"
          style="width: 115px; height: 34px; margin-top: 50px"
          @click="del"
          >删除</el-button
        >
        <el-button
          v-show="ButtonHide"
          v-if="status == '被驳回'"
          style="width: 115px; height: 34px; margin-top: 50px"
          @click="edit"
          >编辑</el-button
        >
      </div>
    </div>
    <!-- 按钮部分 -->
    <!-- <template slot-scope="scope">
             <div> -->

    <!-- </div>
           </template> -->
    <!-- 合格证上传 -->
    <el-dialog title="添加图片" :visible.sync="phoyodialogVisible" width="30%">
      <div class="biaoge">
        <el-upload
          drag
          :multiple="true"
          :limit="1"
          :auto-upload="false"
          accept=".jpg, .jpeg, .png, .JPG, .JPEG, .PNG"
          :action="UploadUrl()"
          :on-change="fileChange"
          :on-exceed="exceedFile"
          :on-success="handleSuccess"
          :on-error="handleError"
          :file-list="fileList"
          :before-remove="beforeRemove"
        >
          <i class="el-icon-upload"></i>
          <div class="el-upload__text">
            将图片拖到此处，或
            <em>点击上传</em>
          </div>
          <div class="el-upload__tip" slot="tip">只能上传图片</div>
        </el-upload>
        <br />
        <el-button size="small" type="primary" @click="uploadFile"
          >立即上传</el-button
        >
        <el-button size="small" @click="quadd">取消</el-button>
      </div>
    </el-dialog>
    <!-- 身份证 -->
    <el-dialog title="添加身份证正面" :visible.sync="shenfenzheng" width="30%">
      <div class="biaoge">
        <el-upload
          drag
          :multiple="true"
          :limit="1"
          :auto-upload="false"
          accept=".jpg, .jpeg, .png, .JPG, .JPEG, .PNG"
          :action="UploadUrl()"
          :on-change="fileChangeshen"
          :on-exceed="exceedFile"
          :on-success="handleSuccess"
          :on-error="handleError"
          :file-list="fileListshen"
          :before-remove="beforeRemoveshen"
        >
          <i class="el-icon-upload"></i>
          <div class="el-upload__text">
            将图片拖到此处，或
            <em>点击上传</em>
          </div>
          <div class="el-upload__tip" slot="tip">只能上传图片</div>
        </el-upload>
        <br />
        <el-button size="small" type="primary" @click="uploadFileshen"
          >立即上传</el-button
        >
        <el-button size="small" @click="quadd">取消</el-button>
      </div>
    </el-dialog>
    <!-- 身份证反面 -->
    <el-dialog title="添加图片" :visible.sync="shenfenzhengbei" width="30%">
      <div class="biaoge">
        <el-upload
          drag
          :multiple="true"
          :limit="1"
          :auto-upload="false"
          accept=".jpg, .jpeg, .png, .JPG, .JPEG, .PNG"
          :action="UploadUrl()"
          :on-change="fileChangeshenbei"
          :on-exceed="exceedFile"
          :on-success="handleSuccess"
          :on-error="handleError"
          :file-list="fileListshenbei"
          :before-remove="beforeRemoveshenbei"
        >
          <i class="el-icon-upload"></i>
          <div class="el-upload__text">
            将图片拖到此处，或
            <em>点击上传</em>
          </div>
          <div class="el-upload__tip" slot="tip">只能上传图片</div>
        </el-upload>
        <br />
        <el-button size="small" type="primary" @click="uploadFileshenbei"
          >立即上传</el-button
        >
        <el-button size="small" @click="quadd">取消</el-button>
      </div>
    </el-dialog>
    <!-- 账号解绑 -->
    <el-dialog title="账号解绑" :visible.sync="jiebang" width="50%" center>
      <div class="da">
        <!-- :model="formLabelAlign" -->
        <el-form :label-position="right" label-width="80px">
          <el-form-item label="机构名称">
            <el-input v-model="orgName" :disabled="true"></el-input>
          </el-form-item>
          <el-form-item label="原因">
            <el-input
              type="textarea"
              :autosize="{ minRows: 1, maxRows: 6 }"
              placeholder="请输入内容"
              v-model="reason"
            ></el-input>
          </el-form-item>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="jiebang = false">取 消</el-button>
        <el-button type="primary" @click="jiebangque">确 定</el-button>
      </span>
    </el-dialog>
    <!-- 金币充值 -->
    <el-dialog title="金币充值" :visible.sync="jinbi" width="50%" center>
      <div class="daa">
        <!-- :model="formLabelAlign" -->
        <el-form :label-position="left" label-width="100px">
          <el-form-item label="机构名称">
            <el-input v-model="orgName" :disabled="true"></el-input>
          </el-form-item>
          <el-form-item label="充值金币(个)">
            <el-input v-model="listGold.coin"></el-input>
          </el-form-item>
          <el-form-item label="充值金额(元)">
            <el-input v-model="listGold.money"></el-input>
          </el-form-item>
          <el-form-item label="合作年限(月)">
            <el-input v-model="listGold.month"></el-input>
          </el-form-item>
        </el-form>
        <div class="endera" @click="Photoadd">支付凭证上传</div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="jinbi = false">取 消</el-button>
        <el-button type="primary" @click="jinbique">确 定</el-button>
      </span>
    </el-dialog>

    <!-- 解冻 -->
    <el-dialog title="解冻" :visible.sync="jieodng" width="50%" center>
      <div class="da">
        <!-- :model="formLabelAlign" -->
        <el-form :label-position="right" label-width="80px">
          <el-form-item label="机构名称">
            <el-input v-model="orgName" :disabled="true"></el-input>
          </el-form-item>
          <el-form-item label="备注">
            <el-input
              type="textarea"
              :autosize="{ minRows: 1, maxRows: 6 }"
              placeholder="请输入内容"
              v-model="reason"
            ></el-input>
          </el-form-item>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="jieodng = false">取 消</el-button>
        <el-button type="primary" @click="jiedongque">确 定</el-button>
      </span>
    </el-dialog>
    <!-- 冻结 -->
    <el-dialog title="冻结" :visible.sync="dongjie" width="50%" center>
      <div class="da">
        <!-- :model="formLabelAlign" -->
        <el-form :label-position="right" label-width="80px">
          <el-form-item label="机构名称">
            <el-input v-model="orgName" :disabled="true"></el-input>
          </el-form-item>
          <el-form-item label="备注">
            <el-input
              type="textarea"
              :autosize="{ minRows: 1, maxRows: 6 }"
              placeholder="请输入内容"
              v-model="reason"
            ></el-input>
          </el-form-item>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dongjie = false">取 消</el-button>
        <el-button type="primary" @click="dongjieque">确 定</el-button>
      </span>
    </el-dialog>
    <!-- 驳回 -->
    <el-dialog title="驳回" :visible.sync="bohui" width="50%" center>
      <div class="da">
        <!-- :model="formLabelAlign" -->
        <el-form :label-position="right" label-width="80px">
          <el-form-item label="机构名称">
            <el-input v-model="orgName" :disabled="true"></el-input>
          </el-form-item>
          <el-form-item label="原因">
            <el-input
              type="textarea"
              :autosize="{ minRows: 1, maxRows: 6 }"
              placeholder="请输入内容"
              v-model="reason"
            ></el-input>
          </el-form-item>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="bohui = false">取 消</el-button>
        <el-button type="primary" @click="bohuique">确 定</el-button>
      </span>
    </el-dialog>
    <!-- 账号更换 -->
    <el-dialog title="账号更换" :visible.sync="right" width="50%" center>
      <div class="da">
        <!-- :model="formLabelAlign" -->
        <el-form :label-position="right" label-width="80px">
          <el-form-item label="机构名称">
            <el-input v-model="orgName" :disabled="true"></el-input>
          </el-form-item>
          <el-form-item label="管理账号">
            <el-input v-model="loginName"></el-input>
          </el-form-item>
          <el-form-item label="手机号">
            <el-input v-model="phone"></el-input>
          </el-form-item>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="right = false">取 消</el-button>
        <el-button type="primary" @click="succ">确 定</el-button>
      </span>
    </el-dialog>
    <!-- 审核通过 -->
    <el-dialog title="审核通过" :visible.sync="rejectDialog" width="50%" center>
      <div class="da">
        <!-- :model="formLabelAlign" -->
        <el-form :label-position="right" label-width="80px">
          <el-form-item label="机构名称">
            <el-input v-model="orgName" :disabled="true"></el-input>
          </el-form-item>
          <el-form-item label="备注">
            <el-input
              type="textarea"
              :autosize="{ minRows: 1, maxRows: 6 }"
              placeholder="请输入内容"
              v-model="reason"
            ></el-input>
          </el-form-item>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="rejectDialog = false">取 消</el-button>
        <el-button type="primary" @click="tongguo">确 定</el-button>
      </span>
    </el-dialog>
    <!-- 权益升级 -->
    <el-dialog title="权益升级" :visible.sync="quanyi" width="900px" center>
      <div class="daaa">
        <el-form :inline="true" class="demo-form-inline">
          <el-form-item label="机构名称">
            <el-input v-model="orgName" :disabled="true"></el-input>
          </el-form-item>
          <el-form-item label="用户等级">
            <el-select placeholder="请选择" v-model="memberLevel">
              <el-option
                v-for="item in options1"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              ></el-option>
            </el-select>
          </el-form-item>
        </el-form>
      </div>
      <div class="div">
        <p style="width: 100px">功能</p>
        <p style="width: 112px">二手商用车快估</p>
        <p style="width: 74px">车型车价</p>
        <p style="width: 74px">登记状态</p>
        <p style="width: 74px">行业主体</p>
        <p style="width: 74px">关注主体</p>
        <p style="width: 74px">关注登记</p>
        <p style="width: 74px">销量数据</p>
        <p style="width: 90px">子账号(个)</p>
      </div>
      <div class="input">
        <p style="width: 100px">增加权益值</p>
        <p style="width: 112px">
          <input
            v-model="listM.yQuickValuation"
            type="text"
            style="width: 50px"
          />
        </p>
        <p style="width: 74px">
          <input v-model="listM.yCarPrice" type="text" style="width: 50px" />
        </p>
        <p style="width: 74px">
          <input v-model="listM.yRegister" type="text" style="width: 50px" />
        </p>
        <p style="width: 74px">
          <input v-model="listM.ySubject" type="text" style="width: 50px" />
        </p>
        <p style="width: 74px">
          <input
            v-model="listM.yFollowSubject"
            type="text"
            style="width: 50px"
          />
        </p>
        <p style="width: 74px">
          <input
            v-model="listM.yFollowRegister"
            type="text"
            style="width: 50px"
          />
        </p>
        <p style="width: 74px">\</p>
        <p style="width: 90px">
          <input v-model="listM.coina" type="text" style="width: 50px" />
        </p>
      </div>
      <div class="inputa">
        <p style="width: 100px">增加月上限</p>
        <p style="width: 112px">
          <input
            type="text"
            v-model="listM.mQuickValuation"
            style="width: 50px"
          />
        </p>
        <p style="width: 74px">
          <input type="text" v-model="listM.mCarPrice" style="width: 50px" />
        </p>
        <p style="width: 74px">
          <input type="text" v-model="listM.mRegister" style="width: 50px" />
        </p>
        <p style="width: 74px">
          <input type="text" v-model="listM.mSubject" style="width: 50px" />
        </p>
        <p style="width: 74px">
          <input
            type="text"
            v-model="listM.mFollowSubject"
            style="width: 50px"
          />
        </p>
        <p style="width: 74px">
          <input
            type="text"
            v-model="listM.mFollowRegister"
            style="width: 50px"
          />
        </p>
        <p style="width: 74px">\</p>
        <p style="width: 90px">\</p>
      </div>

      <span slot="footer" class="dialog-footer">
        <el-button @click="quanyi = false">取 消</el-button>
        <el-button type="primary" @click="quanyique">确 定</el-button>
      </span>
    </el-dialog>
    <!-- 弹框 -->
    <el-dialog
      title="提示"
      :visible.sync="kaifazhong"
      width="30%"
      :before-close="handleClose"
    >
      <span>功能开发中~</span>
      <span slot="footer" class="dialog-footer">
        <!-- <el-button @click="dialogVisible = false">取 消</el-button> -->
        <el-button type="primary" @click="kaifazhong = false">确 定</el-button>
      </span>
    </el-dialog>
    <!-- 编辑 -->
    <el-dialog title="提示" :visible.sync="bianji" width="50%" center>
      <!-- 选项卡 -->
      <!-- 个人企业 -->
      <el-tabs
        v-model="activeName"
        @tab-click="handleClick"
        type="card"
        class="homeMain"
      >
        <!-- 个人 -->
        <el-tab-pane label="个人" name="first" class="deleteBorder">
          <div class="dia">
            <div class="a_diaa_dia">
              <!-- 类型 -->
              <div class="first_dia">类型</div>
              <el-select
                v-model="addForm.orgType"
                class="data_first_dia"
                placeholder="请选择"
              >
                <el-option
                  v-for="item in options"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </el-select>
            </div>
            <!-- 姓名 -->
            <div class="a_diaa_dia">
              <div class="first_dia">姓名</div>
              <input
                type="text"
                class="second_dia"
                @blur.capture="onChangeName"
                v-model="addForm.orgName"
                placeholder="请输入姓名"
              />
            </div>
            <!-- 身份证号 -->
            <div class="a_diaa_dia">
              <div class="first_dia">身份证号</div>
              <input
                type="text"
                class="second_dia"
                maxlength="18"
                v-model="addForm.idCard"
                placeholder="请输入身份证号"
              />
            </div>
            <!-- 管理账号 -->
            <div class="a_diaa_dia">
              <div class="first_dia">管理账号</div>
              <input
                type="text"
                class="second_dia"
                v-model="addForm.loginName"
                placeholder="请输入管理账号"
              />
            </div>
            <!-- 金币充值 -->
            <div class="a_diaa_dia">
              <div class="first_dia">充值金币</div>
              <input
                type="text"
                class="second_dia"
                v-model="addForm.coin"
                placeholder="请输入金币额度"
              />
            </div>
            <!-- 金额充值 -->
            <div class="a_diaa_dia">
              <div class="first_dia">充值金额</div>
              <input
                v-model="addForm.money"
                type="text"
                class="second_dia"
                placeholder="请输入充值金额"
              />
            </div>
            <!-- 手机号 -->
            <div class="a_diaa_dia">
              <div class="first_dia">手机号</div>
              <input
                type="text"
                class="second_dia"
                @blur.capture="onChange"
                v-model="addForm.phone"
                placeholder="请输入手机号"
              />
            </div>
            <!-- 合作时间 -->
            <div class="a_diaa_dia">
              <div class="first_dia">合作时间</div>
              <el-date-picker
                v-model="addForm.time"
                type="daterange"
                unlink-panels
                range-separator="至"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
              ></el-date-picker>
            </div>
            <!-- 会员等级 -->
            <div class="a_diaa_dia">
              <div class="first_dia">会员等级</div>
              <el-select
                v-model="addForm.memberLevel"
                class="data_first_dia"
                @change="memberLevelchang"
                placeholder="请选择"
              >
                <el-option
                  v-for="item in options1"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </el-select>
            </div>
            <!-- 显示隐藏或者回显 -->
            <div>
              <div class="chanpin">产品展示</div>
              <el-table :data="gridData">
                <el-table-column
                  align="center"
                  property="product"
                  label="项目"
                  width="150"
                ></el-table-column>
                <el-table-column
                  align="center"
                  property="countTotal"
                  label="次数"
                  width="200"
                ></el-table-column>
                <el-table-column
                  align="center"
                  property="countMonth"
                  label="最大次数/月"
                >
                  <template slot-scope="scope">
                    <el-input
                      v-model="scope.row.countMonth"
                      @input="getNum"
                      class="group-inp"
                    ></el-input>
                  </template>
                </el-table-column>
                <el-table-column
                  align="center"
                  property="countYear"
                  label="最大次数/年"
                >
                  <template slot-scope="scope">
                    <el-input
                      v-model="scope.row.countYear"
                      @input="getNum"
                      class="group-inp"
                    ></el-input>
                  </template>
                </el-table-column>
              </el-table>
              <div class="ender">
                <div class="endera" @click="Photoaddd">合同上传</div>
                <div class="endera" @click="Photoadd">支付凭证上传</div>
                <div class="endera" @click="Photoadddd">身份证正面</div>
                <div class="endera" @click="Photoaddddd">身份证背面</div>
              </div>
            </div>
            <div class="tijiao" @click="tijiao">提交</div>
          </div>
        </el-tab-pane>
        <!-- 企业 -->
        <el-tab-pane label="企业" name="second">
          <div class="dia">
            <div class="a_diaa_dia">
              <div class="first_dia">类型</div>
              <el-select
                v-model="addForm.orgType"
                class="data_first_dia"
                placeholder="请选择"
              >
                <!-- <el-select v-model="value" placeholder="请选择"> -->
                <el-option
                  v-for="item in options"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </el-select>
            </div>
            <!-- 公司名称 -->
            <div class="a_diaa_dia">
              <div class="first_dia">公司名称</div>
              <input
                type="text"
                class="second_dia"
                @blur.capture="onChangeName"
                v-model="addForm.orgName"
                placeholder="请输入公司名称"
              />
            </div>
            <!-- 信用代码 -->
            <div class="a_diaa_dia">
              <div class="first_dia">统一社会信用代码</div>
              <input
                type="text"
                class="second_dia"
                maxlength="18"
                v-model="addForm.uscCode"
                placeholder="请输入信用代码"
              />
            </div>
            <!-- 管理账号 -->
            <div class="a_diaa_dia">
              <div class="first_dia">管理账号</div>
              <input
                type="text"
                class="second_dia"
                v-model="addForm.loginName"
                placeholder="请输入管理账号"
              />
            </div>
            <div class="a_diaa_dia">
              <div class="first_dia">手机号</div>
              <input
                type="text"
                class="second_dia"
                @blur.capture="onChange"
                v-model="addForm.phone"
                placeholder="请输入手机号"
              />
            </div>
            <!-- 金币充值 -->
            <div class="a_diaa_dia">
              <div class="first_dia">充值金币</div>
              <input
                type="text"
                class="second_dia"
                v-model="addForm.coin"
                placeholder="请输入金币额度"
              />
            </div>
            <!-- 金额充值 -->
            <div class="a_diaa_dia">
              <div class="first_dia">充值金额</div>
              <input
                type="text"
                class="second_dia"
                v-model="addForm.money"
                placeholder="请输入充值金额"
              />
            </div>

            <!-- 合作时间 -->
            <div class="a_diaa_dia">
              <div class="first_dia">合作时间</div>
              <el-date-picker
                v-model="addForm.time"
                type="daterange"
                unlink-panels
                range-separator="至"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
              ></el-date-picker>
            </div>
            <div class="a_diaa_dia">
              <div class="first_dia">会员等级</div>
              <el-select
                v-model="addForm.memberLevel"
                class="data_first_dia"
                @change="memberLevelchang"
                placeholder="请选择"
              >
                <el-option
                  v-for="item in options1"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </el-select>
            </div>

            <div>
              <div class="chanpin">产品展示</div>
              <el-table :data="gridData">
                <el-table-column
                  align="center"
                  property="product"
                  label="项目"
                  width="150"
                ></el-table-column>
                <el-table-column
                  align="center"
                  property="countTotal"
                  label="次数"
                  width="200"
                ></el-table-column>
                <el-table-column
                  align="center"
                  property="countMonth"
                  label="最大次数/月"
                >
                  <template slot-scope="scope">
                    <el-input
                      v-model="scope.row.countMonth"
                      @input="getNum"
                      class="group-inp"
                    ></el-input>
                  </template>
                </el-table-column>
                <el-table-column
                  align="center"
                  property="countYear"
                  label="最大次数/年"
                >
                  <template slot-scope="scope">
                    <el-input
                      v-model="scope.row.countYear"
                      @input="getNum"
                      class="group-inp"
                    ></el-input>
                  </template>
                </el-table-column>
              </el-table>
              <div class="ender">
                <div class="endera" @click="Photoaddd">合同上传</div>
                <div class="endera" @click="Photoadd">支付凭证上传</div>
                <div class="endera" @click="Photoad">营业执照上传</div>
              </div>
            </div>
            <div class="tijiao" @click="tijiao">提交</div>
          </div>
        </el-tab-pane>
      </el-tabs>
      <!-- <span slot="footer" class="dialog-footer">
    <el-button @click="bianji = false">取 消</el-button>
    <el-button type="primary" @click="dialogVisible = false">确 定</el-button>
  </span> -->
    </el-dialog>
    <!-- 111111111111111111111111111111111111111111111111 -->

    <!-- 11111111111111111111111111111111111111111 -->
  </div>
</template>
<script>
import { color } from "echarts";
let token = sessionStorage.getItem("token");
export default {
  data() {
    return {
      activeNmae: "first", //选项卡
      activeName: "second",
      fileList: [], // excel文件列表
      file: "",
      limit: 1,
      phoyodialogVisible: false,
      kaifazhong: false,
      // url:"dealPay",
      options: [
        {
          value: "金融机构",
          label: "金融机构",
        },
        {
          value: "主机厂",
          label: "主机厂",
        },
        {
          value: "物流企业",
          label: "物流企业",
        },
        {
          value: "政府机构",
          label: "政府机构",
        },
        {
          value: "经销商",
          label: "经销商",
        },
      ],
      options1: [
        {
          value: "V2",
          label: "V2",
        },
        {
          value: "V3",
          label: "V3",
        },
        {
          value: "V4",
          label: "V4",
        },
        {
          value: "V5",
          label: "V5",
        },
        {
          value: "V6",
          label: "V6",
        },
        {
          value: "V7",
          label: "V7",
        },
        {
          value: "V8",
          label: "V8",
        },
      ],
      ButtonHide: "",
      // succ:"true",
      loginName: "",
      phone: "",
      value: "",
      listM: {
        yQuickValuation: 0,
        yCarPrice: 0,
        yRegister: 0,
        ySubject: 0,
        yFollowSubject: 0,
        yFollowRegister: 0,
        coina: 0,
        mQuickValuation: 0,
        mCarPrice: 0,
        mregisterm: 0,
        mSubject: 0,
        mRegister: 0,
        mFollowSubject: 0,
        mFollowRegister: 0,
        childNum: 0,
        coina: 0,
      },
      listFrom: {
        conditions: "", //编辑记录
        userName: "", //操作人
        phone: "", //操作账号
        createTime: "", //操作时间
        coin: "", //操作
        pageSize: "10", //分页页数
        pageNum: "1", //分页页码
        companyName: "",
        status: "",
      },
      listStatus: {
        approved: null, //正常
        reject: null, //被驳回
        ending: null, //到期
        frozen: null, //冻结
        pending: null, //待审核
      },
      listGold: {
        coin: "",
        money: "",
        month: "",
      },
      cooperationMember: "",
      myHeaders: { token }, //token
      orgName: "", //机构名称
      type: "", //机构类别
      orgType: "", //机构类型
      uscCode: "", //统一社会信用代码
      memberLevel: "", //用户登记
      money: "", //充值金额
      startTime: "", //开通时间
      endTime: "", //结束时间
      status: "", //账号状态
      loginName: "", //管理账号
      phone: "", //手机号
      ycarPrice: "", //剩余年车型车架
      yfollowRegister: "", //剩余关注登记
      yfollowSubject: "", //剩余关注登记
      ysubject: "", //剩余行业主体
      yregister: "", //剩余登记状态
      yquickValuation: "", //剩余商用车快估
      childNum: "", //剩余子账号个数
      coin: "", //剩余金币
      mcarPrice: "", //本月剩余
      mregister: "", //剩余登记状态
      mfollowSubject: "", //月剩余关注主体
      mfollowRegister: "", //月剩余登记状态
      mquickValuation: "", //月剩余快估
      yquickValuationy: "", //年权益，快估
      ycarPricey: "", //年权益，车型车架
      yregistery: "", //年权益，登记状态
      ysubjecty: "", //年权益，行业主体
      yfollowRegistery: "", //年权益，关注登记
      yfollowSubjecty: "", //年权益，关注主体
      mquickValuationm: "", //月上限快估
      mcarPricem: "", //上限车型 车架
      mregisterm: "", //上限登记状态
      mfollowSubjectm: "", //上限关注
      mfollowRegisterm: "", //上线关注登记
      msubject: "",
      msubjectm: "",
      coina: "", //剩余金币
      childNuma: "", //剩余子账号个数
      contract: "", //合同编号
      dealPay: "", //支付凭证
      businessLicense: "", //营业执照
      createUser: "", //注册人
      createTime: "", //注册时间
      updateUser: "", //审核人
      updateTime: "", //审核时间
      idCard: "", //身份证号
      money: "", //
      list: [], //表格数据
      total: null, //条数
      ButtonHide: true, //不同状态显示或者隐藏
      Id: "",
      getId: null, //id
      rejectDialog: false, //通过
      rejectDialogVisible: false, //驳回
      right: false, //账号更换
      bohui: false, //驳回
      reason: "", //驳回理由
      sta: "", //状态 ，驳回
      dongjie: false, //冻结
      jiedong: false, //解冻
      jinbi: false, //金币充值
      jiebang: false, //账号解绑
      quanyi: false, //权益编辑
      jieodng: false, //解冻
      id: "", //id
      bianji: false,
      gridData: [],
      getNums: "",
      idCardBehind: [],
      idCardFront: [],
      businessLicense: "",
      contract: "",
      shenfenzheng: false, //身份证
      shenfenzhengbei: false, //身份证反面
      fileListshen: [], //身份证的数组
      fileListshenbei: [], //身份证背面的数组
      activeName: "second",
      shenfenzheng: false, //身份证
      shenfenzhengbei: false, //身份证反面
      fileListshen: [], //身份证的数组
      fileListshenbei: [], //身份证背面的数组
      activeNmae: "first", //选项卡
      dialogVisibleqx: false,
      isShow: true,
      total: 0,
      totald: 0,
      photo: [],
      photod: [],
      tDialogVisible: false,
      edutDialogVisible: false,
      limit: 10,
      fileList: [], // excel文件列表
      file: "",
      // 控制合格证的显示与隐藏
      phoyodialogVisible: false,
      phoyodialog: 1,
      addNumberdialogVisible: false, //控制子账号添加的弹框
      gridData: [],
      companyDialogVisible: false, //点击公司 展示公司信息
      status: true, //判断正式会员
      //判断非正式会员
      emptyText: "请输入机构名称查询",
      file: "",
      dialogVisible: false,
      blurKey: "", //用户输入的
      dialogVisible: false, //控制对话框的显示隐藏
      tableData: [], //正式会员的参数
      tableDA: [], //非正式会员的参数
      multipleSelection: [],
      subjectId: "",
      userlist: "",
      member: "",
      addForm: {
        orgType: "", //类型
        orgName: "", //公司名称
        uscCode: "", //信用代码
        coin: "",
        money: "",
        memberLevel: "", //会员等级
        loginName: "", //管理账号
        phone: "", //手机号
        password: "", //密码
        time: "", //时间
        dealPay: "", //支付凭证
        contract: "", //合同
        businessLicense: "", //营业执照上传
        idCardFront: "", //身份证正面
        idCardBehind: "", //身份证背面
        idCard: "", //身份证号码
        list: null, //下面展示的数据
        id: "",
      },
      getNums: "",
      tiJiaoOne: true,
      phoyodialog: 1,
      gridData: [],
      idz: "",
    };
  },
  computed: {},
  methods: {
    // 点击开通会员提交
    async tijiao() {
      // if (!this.tiJiaoOne) {
      //   return this.$message.error("手机号有误");
      // }
      this.addForm.list = this.gridData;
      console.log(this.addForm);
      let num = 0;
      let gum = 0;
      if (
        this.addForm.orgType == "" ||
        this.addForm.orgName == "" ||
        this.addForm.loginName == "" ||
        this.addForm.coin == "" ||
        this.addForm.money == "" ||
        this.addForm.phone == "" ||
        this.addForm.list == [] ||
        // this.addForm.dealPay == [] ||
        this.addForm.time == [] ||
        this.addForm.memberLevel == ""
      ) {
        return this.$message.error("请补充信息");
      }
      this.addForm.list.forEach((item) => {
        if (item.countMonth == "") {
          num++;
        }
        var numReg = /^[0-9]*$/;
        var numRe = new RegExp(numReg);
        if (!numRe.test(item.countMonth)) {
          console.log("不能发送");
          gum++;
        }
      });
      if (num != 0) return this.$message.error("请补充信息");
      if (gum != 0) return this.$message.error("请补充信息");

      const { data: res } = await this.$http({
        method: "post",
        url: "cooperationMember/addCooperationMember",
        data: this.addForm,
      });
      if (res.code !== 200) return this.$message.error("开通账号失败");
      this.$message.success("开通账号成功");
      this.addForm = {};
      (this.addForm.contract = ""),
        (this.addForm.dealPay = ""),
        (this.addForm.businessLicense = ""),
        // (this.addForm.id)
        (this.bianji = false);
      // this.getOrg(this.$route.query.id);
      this.$router.go(-1);
    },
    // input失去焦点时间
    async onChangeName() {
      console.log("失去焦点了");
      if (this.addForm.orgName == "") {
        this.$message.error("不能为空");
        this.tiJiaoOne = false;
        return;
      }
      const { data: res } = await this.$http.get(
        `/cooperationMember/isCompanyName/${this.addForm.orgName}`
        // `/cooperationMember/isPhone/${this.addForm.phone}`
      );
      console.log(res);
      if (res.code == 708) {
        this.$message.error("主体名称已存在");
        this.tiJiaoOne = false;
        return;
      } else {
        this.$message.success("主体名称可以使用");
        this.tiJiaoOne = true;
      }
    },
    // input失去焦点时间
    async onChange() {
      console.log("失去焦点了");
      if (this.addForm.phone == "") {
        this.$message.error("手机号不能为空");
        this.tiJiaoOne = false;
        return;
      }

      if (!/^1[34578]\d{9}$/.test(this.addForm.phone)) {
        this.$message.error("请填写正确的手机号");
        this.tiJiaoOne = false;
        return;
      }

      const { data: res } = await this.$http.get(
        `/cooperationMember/isPhone/${this.addForm.phone}`
      );
      console.log(res);
      if (res.code == 707) {
        this.$message.error("手机号已经绑定主体");
        this.tiJiaoOne = false;
        return;
      } else {
        this.$message.success("手机号可以使用");
        this.tiJiaoOne = true;
      }
    },
    uploadFile() {
      console.log(this.fileList);
      if (this.fileList.length === 0) {
        this.$message.warning("请上传图片");
      } else {
        const len = this.fileList.length;
        let num = 0;
        this.fileList.forEach((item) => {
          let form = new FormData();
          this.file = item;
          form.append("attachFile", this.file);

          this.$http({
            method: "post",
            url: "cooperationMember/attachFile",

            headers: {
              "Content-type": "multipart/form-data",
            },
            data: form,
          }).then(
            (res) => {
              if (res.data.code == 200) {
                num++;
                // this.$message.success("添加图片成功");
                // this.dialogVisible = false;
                if (num === len) {
                  this.$message.success("添加图片成功");
                  this.phoyodialogVisible = false;
                  console.log(res.data.data);
                  // this.addForm.idCardFront = res.data.data;
                  this.fileList = [];
                }
              } else if (res.data.code == 701) {
                this.$message.success("图片格式不对，请上传正确的图片格式");
              } else {
                this.$message.error("添加图片失败");
              }
              console.log(this.phoyodialog);

              // console.log(this.dealPay);
              // return
              if (this.phoyodialog == 1) {
                // this.addForm.dealPay.push({ url: res.data.data });
                this.addForm.dealPay = res.data.data;
              }
              if (this.phoyodialog == 2) {
                // this.addForm.businessLicense.push({ url: res.data.data });
                this.addForm.businessLicense = res.data.data;
              }
              if (this.phoyodialog == 3) {
                // this.addForm.contract.push({ url: res.data.data });
                this.addForm.contract = res.data.data;
              }
              // if(this.phoyodialog==6){
              // this.dealPay=res.data.data;
              // }
            },
            (err) => {}
          );
        });

        console.log(this.file);
      }
    },
    uploadFileshen() {
      console.log(this.fileListshen);

      if (this.fileListshen.length === 0) {
        this.$message.warning("请上传图片");
      } else {
        const len = this.fileListshen.length;
        let num = 0;
        this.fileListshen.forEach((item) => {
          let form = new FormData();
          this.file = item;
          form.append("attachFile", this.file);
          this.$http({
            method: "post",
            url: "cooperationMember/attachFile",
            headers: {
              "Content-type": "multipart/form-data",
            },
            data: form,
          }).then(
            (res) => {
              if (res.data.code == 200) {
                num++;
                if (num === len) {
                  this.$message.success("添加图片成功");
                  this.phoyodialogVisible = false;
                  console.log(res.data.data);
                  // this.addForm.idCardFront = res.data.data;
                  // this.fileListshen = [];
                }
              } else if (res.data.code == 701) {
                this.$message.success("图片格式不对，请上传正确的图片格式");
              } else {
                this.$message.error("添加图片失败");
              }
              console.log(this.phoyodialog);
              if (this.phoyodialog == 1) {
                // this.addForm.dealPay.push({ url: res.data.data });
                this.addForm.dealPay = res.data.data;
              }
              if (this.phoyodialog == 2) {
                // this.addForm.businessLicense.push({ url: res.data.data });
                this.addForm.businessLicense = res.data.data;
              }
              if (this.phoyodialog == 3) {
                // this.addForm.contract.push({ url: res.data.data });
                this.addForm.contract = res.data.data;
              }
            },
            (err) => {}
          );
        });

        console.log(this.file);
      }
    },
    // 身份证背面
    uploadFileshenbei() {
      console.log(this.fileListshenbei);
      if (this.fileListshenbei.length === 0) {
        this.$message.warning("请上传图片");
      } else {
        const len = this.fileListshenbei.length;
        let num = 0;
        this.fileListshenbei.forEach((item) => {
          let form = new FormData();
          this.file = item;
          form.append("attachFile", this.file);
          this.$http({
            method: "post",
            url: "cooperationMember/attachFile",
            headers: {
              "Content-type": "multipart/form-data",
            },
            data: form,
          }).then(
            (res) => {
              if (res.data.code == 200) {
                num++;
                if (num === len) {
                  this.$message.success("添加图片成功");
                  this.shenfenzhengbei = false;
                  console.log(res.data.data);
                  // this.addForm.idCardBehind = res.data.data;

                  // this.fileListshenbei = [];
                }
              } else if (res.data.code == 701) {
                this.$message.success("图片格式不对，请上传正确的图片格式");
              } else {
                this.$message.error("添加图片失败");
              }
              console.log(this.phoyodialog);
              if (this.phoyodialog == 1) {
                // this.addForm.dealPay.push({ url: res.data.data });
                this.addForm.dealPay = res.data.data;
              }
              if (this.phoyodialog == 2) {
                // this.addForm.businessLicense.push({ url: res.data.data });
                this.addForm.businessLicense = res.data.data;
              }
              if (this.phoyodialog == 3) {
                // this.addForm.contract.push({ url: res.data.data });
                this.addForm.contract = res.data.data;
              }
            },
            (err) => {}
          );
        });

        console.log(this.file);
      }
    },

    // 选项卡
    handleClick(tab, event) {
      console.log(tab, event);
    },

    quadd() {
      this.phoyodialogVisible = false;
    },
    // 点击合格证上传
    Photoadd() {
      this.phoyodialog = 1;
      this.phoyodialogVisible = true;
    },
    Photoad() {
      this.phoyodialog = 2;
      this.phoyodialogVisible = true;
    },
    Photoaddd() {
      this.phoyodialog = 3;
      this.phoyodialogVisible = true;
    },
    Photoadddd() {
      this.phoyodialog = 4;
      this.phoyodialogVisible = true;
    },
    Photoaddddd() {
      this.phoyodialog = 5;
      this.phoyodialogVisible = true;
    },
    Pd() {
      this.phoyodialog = 6;
      this.phoyodialogVisible = true;
    },
    // 文件超出个数限制时的钩子
    exceedFile(files, fileList) {
      this.$message.warning(
        `只能选择1个图片，当前共选择了 ${files.length + fileList.length} 个`
      );
    },
    // 文件状态改变时的钩子
    fileChange(file, fileList) {
      this.fileList.push(file.raw);
    },
    fileChangeshen(file, fileList) {
      this.fileListshen.push(file.raw);
    },
    fileChangeshenbei(file, fileList) {
      this.fileListshenbei.push(file.raw);
    },
    beforeRemove(file, fileList) {
      this.fileList = fileList;
      console.log(this.fileList);
    },
    beforeRemoveshen(file, fileListshen) {
      this.fileListshen = fileListshen;
      console.log(this.fileListshen);
    },
    beforeRemoveshenbei(file, fileListshenbei) {
      this.fileListshenbei = fileListshenbei;
      console.log(this.fileListshenbei);
    },
    // 文件上传成功时的钩子
    handleSuccess(res, file, fileList) {
      this.$message.success("图片上传成功");
    },
    // 文件上传失败时的钩子
    handleError(err, file, fileList) {
      this.$message.error("图片上传失败");
    },
    UploadUrl: function () {
      // 因为action参数是必填项，我们使用二次确认进行文件上传时，直接填上传文件的url会因为没有参数导致api报404，所以这里将action设置为一个返回为空的方法就行，避免抛错
      return "";
    },
    // 11111111111111111111
    //删除按钮
    del() {
      this.$confirm("删除后将无法找回，是否确认删除？", "提示", {
        confirmButtonText: "删除",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(async () => {
          const { data: res } = await this.$http({
            method: "delete",
            url: `/manager/delReject/${this.orgName}`,
          });
          if (res.code != 200) return this.message.error("错误");
          this.$message.success("删除成功");
          // this.getList();
          this.$router.go(-1);
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
    // 编辑
    async edit() {
      this.bianji = true;
      console.log(this.cooperationMember);
      this.addForm = this.cooperationMember;
    },
    async memberLevelchang(e) {
      console.log(e);
      const { data: res } = await this.$http({
        method: "get",
        url: "cooperationMember/getMemberLevel?level=" + e,
      });
      if (res.code !== 200) return this.$message.error("状态改变失败");

      this.$message.success("状态改变成功");
      console.log(res.data);
      this.gridData = res.data;
      console.log(this.gridData);
      // console.log(1112121)
    },
    getNum(value) {
      this.getNums = value;
    },
    // 返回按钮
    back() {
      console.log(1111);
      this.$router.go(-1);
    },
    //权益编辑
    async equity() {
      this.quanyi = true;
    },
    // 权益编辑
    async quanyique() {
      let listM = this.listM;
      const { data: res } = await this.$http({
        method: "get",
        url: `/manager/edit?companyName=${this.$route.query.id.orgName}&childNum=${listM.childNum}&yCarPrice=${listM.yCarPrice}&yQuickValuation=${listM.yQuickValuation}&ySubject=${listM.ySubject}&yRegister=${listM.yRegister}&yFollowSubject=${listM.yFollowSubject}&yFollowRegister=${this.listM.yFollowRegister}&memberLevel=${this.memberLevel}&mCarPrice=${listM.mCarPrice}&mQuickValuation=${listM.mQuickValuation}&mSubject=${listM.mSubject}&mRegister=${listM.mRegister}&mFollowSubject=${listM.mFollowSubject}&mFollowRegister=${listM.mFollowRegister}`,
      });
      if (res.code !== 200) return;
      console.log(res.data);
      this.quanyi = false;
      this.listM.yQuickValuation = "0";
      this.listM.yCarPrice = "0";
      this.listM.yRegister = "0";
      this.listM.ySubject = "0";
      this.listM.yFollowSubject = "0";
      this.listM.yFollowRegister = "0";
      this.listM.coina = "0";
      this.listM.mQuickValuation = "0";
      this.listM.mCarPrice = "0";
      this.listM.mregisterm = "0";
      this.listM.mSubject = "0";
      this.listM.mRegister = "0";
      this.listM.mFollowSubject = "0";
      this.listM.mFollowRegister = "0";
      this.listM.childNum = "0";

      //  this.$router.go(-1);
      this.getOrg(this.$route.query.id);
    },
    // 账号解绑
    async unbundle() {
      this.jiebang = true;
    },
    //账号解绑
    async jiebangque() {
      this.$confirm("确认解绑？")
        .then(async (_) => {
          const { data: res } = await this.$http({
            method: "delete",
            url: `/cooperationMember/delMember?orgName=${this.orgName}&reason=${this.reason}`,
          });
          if (res.code !== 200) return;
          console.log(res.data);
          this.jiebang = false;
          this.$router.go(-1);
        })
        .catch((_) => {});
      //  this.getOrg(this.$route.query.id);
    },
    //金币充值
    async gold() {
      this.jinbi = true;
    },
    //金币充值
    async jinbique() {
      let listGold = this.listGold;
      const { data: res } = await this.$http({
        method: "get",
        // url: `/manager/recharge?companyName=${this.orgName}&coin=${listGold.coin}&money=${listGold.money}&month=${listGold.month}&certificateUrl=https://vehicle-library.oss-cn-beijing.aliyuncs.com/CooperationMember/ffdc1161-b680-4035-bed6-79adda16d09a.jpg`,
        url: `/manager/recharge?companyName=${this.orgName}&coin=${listGold.coin}&money=${listGold.money}&month=${listGold.month}&certificateUrl=${this.addForm.dealPay}`,
      });
      if (res.code !== 200) return;

      console.log(res.data);
      this.jinbi = false;
      // this.$router.go(-1);
      this.listGold.month = "";
      this.listGold.coin = "";
      this.listGold.money = "";
      this.getOrg(this.$route.query.id);
    },
    // 解冻
    async unfree() {
      this.jieodng = true;
    },
    //解冻
    async jiedongque() {
      const { data: res } = await this.$http({
        method: "get",
        url: `/cooperationMember/refrozenMember/${this.orgName}/${this.reason}`,
      });
      if (res.code !== 200) return;
      console.log(res.data);
      this.jieodng = false;
      //  this.$router.go(-1);
      this.reason = "";
      this.getOrg(this.$route.query.id);
    },
    // 冻结
    async free() {
      this.dongjie = true;
    },
    // 冻结
    async dongjieque() {
      const { data: res } = await this.$http({
        method: "get",
        url: `/cooperationMember/frozenMember/${this.orgName}/${this.reason}`,
      });
      if (res.code !== 200) return;
      console.log(res.data);
      this.dongjie = false;
      //  this.$router.go(-1);
      this.reason = "";
      this.getOrg(this.$route.query.id);
    },
    // 驳回
    async turn() {
      this.bohui = true;
    },
    //驳回
    async bohuique() {
      this.sta = "reject";
      const { data: res } = await this.$http({
        method: "get",
        url: `/cooperationMember/approveCooperationMember?cooperationId=${this.id}&status=${this.sta}&reason=${this.reason}`,
      });
      if (res.code !== 200) return;
      console.log(res.data);
      this.bohui = false;
      //  this.$router.go(-1);
      this.reason = "";
      this.getOrg(this.$route.query.id);
    },
    //  审核通过
    async pass() {
      this.rejectDialog = true;
    },
    //  审核通过
    async tongguo() {
      // let   listStatus  =this.listStatus
      this.sta = "approved";
      const { data: res } = await this.$http({
        method: "get",
        url: `cooperationMember/approveCooperationMember?cooperationId=${this.id}&status=${this.sta}&reason=${this.reason}`,
      });
      if (res.code !== 200) return;
      console.log(res.data);
      this.rejectDialog = false;
      //  this.$router.go(-1);
      this.reason = "";
      this.getOrg(this.$route.query.id);
    },
    // 账号更换
    async number() {
      this.right = true;
    },
    // 账号更换
    async succ() {
      const { data: res } = await this.$http({
        method: "get",
        url: `manager/update?companyName=${this.orgName}&loginName=${this.loginName}&phone=${this.phone}`,
      });

      if (res.code == 100) {
        this.$message.error("此用户不存在");
      }
      if (res.code == 683) {
        this.$message.error("该账号已为超级管理员");
      }
      if (res.code !== 200) return;

      this.right = false;
      this.getOrg();
    },

    // 分页器
    handleSizeChange(e) {
      console.log(e);
      this.listFrom.pageSize = e;
      this.getOrg();
    },
    handleCurrentChange(e) {
      this.listFrom.pageNum = e;
      this.getOrg();
    },

    //   //  详情数据
    async getOrg() {
      // console.log(id);
      let listFrom = this.listFrom;
      const { data: res } = await this.$http({
        method: "get",
        // url: `/manager/getOrgManager?companyName=刘广来&pageSize=${listFrom.pageSize}&pageNum=${listFrom.pageNum}`,
        url: `/manager/getOrgManager?&companyName=${this.$route.query.id.orgName}&pageSize=${listFrom.pageSize}&pageNum=${listFrom.pageNum}`,
      });
      if (res.code !== 200) return;
      // console.log(res.data.remainder.orgName);
      // this.companyName =res.data.cooperationMember.companyName
      // console.log(companyName);
      this.orgName = res.data.remainder.orgName; //机构名称
      this.type = res.data.cooperationMember.type; //机构类别
      this.uscCode = res.data.cooperationMember.uscCode; //统一社会信用代码
      this.orgType = res.data.cooperationMember.orgType; //机构类型
      this.memberLevel = res.data.cooperationMember.memberLevel; //用户等级
      this.money = res.data.cooperationMember.money; //充值金额
      this.startTime = res.data.remainder.startTime; //开通时间
      this.endTime = res.data.cooperationMember.endTime; //结束时间
      this.status = res.data.cooperationMember.status; //账号状态
      this.loginName = res.data.cooperationMember.loginName; //管理账号
      this.phone = res.data.cooperationMember.phone; //手机号
      this.ycarPrice = res.data.remainder.ycarPrice; //剩余，车型车架
      this.yfollowRegister = res.data.remainder.yfollowRegister; //剩余关注登记
      this.yfollowSubject = res.data.remainder.yfollowSubject; //剩余关注主体
      this.ysubject = res.data.remainder.ysubject; //剩余行业主体
      this.yregister = res.data.remainder.yregister; //剩余登记状态
      this.yquickValuation = res.data.remainder.yquickValuation; //剩余商用车快估
      this.coin = res.data.remainder.coin; //剩余金币
      this.childNum = res.data.remainder.childNum; //剩余子账号
      this.mcarPrice = res.data.remainder.mcarPrice; //月剩余车
      this.msubject = res.data.remainder.msubject; //月剩余行业主体
      this.mregister = res.data.remainder.mregister; //月剩余登记状态
      this.mfollowSubject = res.data.remainder.mfollowSubject; //月剩余关注主体
      this.mquickValuation = res.data.remainder.mquickValuation; //月剩余快估
      this.mfollowRegister = res.data.remainder.mfollowRegister; //月剩余登记状态
      this.yquickValuationy = res.data.cooperationMember.yquickValuation; //年权益值商用车
      this.ycarPricey = res.data.cooperationMember.ycarPrice; //年权益值，车型车架
      this.yregistery = res.data.cooperationMember.yregister; //年权益登记状态
      this.ysubjecty = res.data.cooperationMember.ysubject; //年权行业主体
      this.yfollowSubjecty = res.data.cooperationMember.yfollowSubject; //年关注主体
      this.yfollowRegistery = res.data.cooperationMember.yfollowRegister; //年关注登记
      this.mquickValuationm = res.data.cooperationMember.mquickValuation; //月上限快估
      this.mcarPricem = res.data.cooperationMember.mcarPrice; //上限车型车架
      this.mregisterm = res.data.cooperationMember.mregister; //上限登记状态
      this.msubjectm = res.data.cooperationMember.msubject; //上限行业主体
      this.mfollowSubjectm = res.data.cooperationMember.mfollowSubject; //上限关注主体
      this.mfollowRegisterm = res.data.cooperationMember.mfollowRegister; //上限制关注登记
      this.coina = res.data.cooperationMember.coin; //年权益剩余金币
      this.childNuma = res.data.cooperationMember.childNum; //年权益剩余子账号
      this.cooperationMember = res.data.cooperationMember;
      this.id = res.data.cooperationMember.id;
      console.log(this.addForm);
      this.contract = res.data.cooperationMember.contract; //合同编号
      this.dealPay = res.data.cooperationMember.dealPay; //支付凭证
      this.businessLicense = res.data.cooperationMember.businessLicense; //营业执照
      this.createUser = res.data.cooperationMember.createUser; //注册人
      this.createTime = res.data.cooperationMember.createTime; //注册时间
      this.updateUser = res.data.cooperationMember.updateUser; //审核人
      this.updateTime = res.data.cooperationMember.updateTime; //审核时间
      this.status = res.data.cooperationMember.status;
      this.gridData = res.data.cooperationMember.levelInfo;
      console.log(this.status);
      this.list = res.data.manageList.list;
      this.total = res.data.manageList.total; //条数
      this.id = res.data.cooperationMember.id;
      console.log(this.id);
      this.idCard = res.data.cooperationMember.idCard; //身份证号
      this.money = res.data.cooperationMember.money; //钱
      this.idCardFront = res.data.cooperationMember.idCardFront;
      this.idCardBehind = res.data.cooperationMember.idCardBehind;
      //      approved:null,   //正常
      // reject:null,    //被驳回
      // ending:null,     //到期
      // frozen:null,     //冻结
      // pending:null,    //待审核
      if (this.status == "approved") {
        this.status = "正常";
        // this.status={color:'#7CD601'}
      }
      if (this.status == "reject") {
        this.status = "被驳回";
        // this.status={color:'#7CD601'}
      }
      if (this.status == "ending") {
        this.status = "到期";
        // this.status={color:'#7CD601'}
      }
      if (this.status == "frozen") {
        this.status = "冻结";
        // this.status={color:'#7CD601'}
      }
      if (this.status == "pending") {
        this.status = "待审核";
        // this.status={color:'#7CD601'}
      }
      if (this.type == "1") {
        this.type = "企业";
      } else if (this.type == "2") {
        this.type = "个人";
      }
    },
  },
  mounted() {
    // this.getId  = this.$route.query.id;
    console.log(this.$route.query.id);
    this.getOrg(this.$route.query.id.orgName);
    //  this.getOrg();
    // if(this.$route.query.index ==1){
    //   console.log(查看)
    //     let  seelook =sessionStorage.getItem("seelook")
    //     this.titlist =JSON.parse(seelook);
    //     (this.getOrg={
    //     })
    // }
    if (this.$route.query.index == 1) {
      console.log("被驳回管理进入");
      //  返回  编辑  删除
    } else if (this.$route.query.index == 2) {
      console.log("查看进入");
      this.ButtonHide = false; //让我们的按钮消失
    }
  },
  created() {
    //  if(this.$route.query.index==1){
    //      let  seelook  = sessionStorage.getItem("seelook");
    //      this.titlist =JSON.parse(seelook);
    //  }
    // console.log(this.$route.query.id);
    // (this.ButtonHide=false) ;//按钮显示隐藏
    //  console.log(this.$route.query.id);
  },
};
</script>

<style scoped lang="less">
.box {
  width: 100%;
  .header {
    margin-top: 10px;
    width: 1000px;
    height: 242px;
    background: #ffffff;
    box-shadow: 0px 0px 10px #bfdff7;
    opacity: 1;
    border-radius: 10px;
    overflow: hidden;
    .biaoti {
      margin-left: 24px;
      margin-top: 24px;
      width: 82px;
      height: 20px;
      font-size: 20px;
      font-family: AppleSystemUIFont;
      font-weight: 400;
      line-height: 23px;
      color: #000000;
      opacity: 1;
    }
    .messageOne {
      margin-left: 24px;
      margin-top: 18px;
      width: 976px;
      height: 22px;
      // background: blueviolet;
      .spanone {
        display: block;
        width: 64px;
        height: 22px;
        // height: 22px;
        font-size: 16px;
        font-family: PingFang SC;
        font-weight: 400;
        line-height: 22px;
        color: #666666;
        opacity: 1;
        float: left;
      }
      .spantwo {
        height: 22px;
        float: left;
        display: block;
        width: 223px;
        // height: 22px;
        font-size: 16px;
        font-family: PingFang SC;
        font-weight: 500;
        line-height: 22px;
        color: #333333;
        opacity: 1;
        margin-left: 20px;
      }
      .spanthree {
        height: 22px;
        margin-left: 39px;
        float: left;
        display: block;
        width: 64px;
        font-size: 16px;
        font-family: PingFang SC;
        font-weight: 400;
        line-height: 22px;
        color: #666666;
        opacity: 1;
      }
      .spanfour {
        height: 22px;
        margin-left: 39px;
        float: left;
        display: block;
        width: 152px;
        // height: 22px;
        font-size: 16px;
        font-family: PingFang SC;
        font-weight: 500;
        line-height: 22px;
        color: #333333;
        opacity: 1;
      }
      .spanfive {
        height: 22px;
        display: block;
        width: 128px;
        float: left;
        font-size: 16px;
        font-family: PingFang SC;
        font-weight: 400;
        line-height: 22px;
        color: #666666;
        opacity: 1;
      }
      .spansix {
        height: 22px;
        display: block;
        margin-left: 20px;
        float: left;
        width: 222px;
        font-size: 16px;
        font-family: PingFang SC;
        font-weight: 500;
        line-height: 22px;
        color: #333333;
        opacity: 1;
      }
    }
    .messageTwo {
      height: 22px;
      margin-left: 24px;
      margin-top: 20px;
      width: 976px;
      height: 22px;
      // background: lemonchiffon;
      .spanone {
        height: 22px;
        display: block;
        width: 64px;
        // height: 22px;
        font-size: 16px;
        font-family: PingFang SC;
        font-weight: 400;
        line-height: 22px;
        color: #666666;
        opacity: 1;
        float: left;
      }
      .spantwo {
        height: 22px;
        float: left;
        display: block;
        width: 223px;
        // height: 22px;
        font-size: 16px;
        font-family: PingFang SC;
        font-weight: 500;
        line-height: 22px;
        color: #333333;
        opacity: 1;
        margin-left: 20px;
      }
      .spanthree {
        height: 22px;
        margin-left: 39px;
        float: left;
        display: block;
        width: 64px;
        font-size: 16px;
        font-family: PingFang SC;
        font-weight: 400;
        line-height: 22px;
        color: #666666;
        opacity: 1;
      }
      .spanfoura {
        height: 22px;
        margin-left: 39px;
        float: left;
        display: block;
        width: 186px;
        // height: 22px;
        font-size: 16px;
        font-family: PingFang SC;
        font-weight: 500;
        line-height: 22px;
        color: #333333;
        opacity: 1;
      }
      .spanfive {
        height: 22px;
        margin-left: 30px;
        display: block;
        width: 64px;
        float: left;
        font-size: 16px;
        font-family: PingFang SC;
        font-weight: 400;
        line-height: 22px;
        color: #666666;
        opacity: 1;
      }
      .spansix {
        height: 22px;
        display: block;
        margin-left: 20px;
        float: left;
        width: 222px;
        font-size: 16px;
        font-family: PingFang SC;
        font-weight: 500;
        line-height: 22px;
        color: #333333;
        opacity: 1;
      }
    }
    // memberManagementLook
    .messageThree {
      margin-left: 24px;
      margin-top: 20px;
      width: 976px;
      height: 22px;
      // background: navajowhite;
      .spanone {
        height: 22px;
        display: block;
        width: 64px;
        // height: 22px;
        font-size: 16px;
        font-family: PingFang SC;
        font-weight: 400;
        line-height: 22px;
        color: #666666;
        opacity: 1;
        float: left;
      }
      .spantwo {
        height: 22px;
        float: left;
        display: block;
        width: 223px;
        // height: 22px;
        font-size: 16px;
        font-family: PingFang SC;
        font-weight: 500;
        line-height: 22px;
        color: #333333;
        opacity: 1;
        margin-left: 20px;
      }
      .spanthreea {
        height: 22px;
        margin-left: 12px;
        float: left;
        display: block;
        width: 91px;
        font-size: 16px;
        font-family: PingFang SC;
        font-weight: 400;
        line-height: 22px;
        color: #666666;
        opacity: 1;
      }
      .spanfoura {
        height: 22px;
        margin-left: 39px;
        float: left;
        display: block;
        width: 186px;
        // height: 22px;
        font-size: 16px;
        font-family: PingFang SC;
        font-weight: 500;
        line-height: 22px;
        color: #333333;
        opacity: 1;
      }
      .spanfivea {
        height: 22px;
        display: block;
        margin-left: 30px;
        width: 64px;
        float: left;
        font-size: 16px;
        font-family: PingFang SC;
        font-weight: 400;
        line-height: 22px;
        color: #666666;
        opacity: 1;
      }
      .spansix {
        height: 22px;
        display: block;
        margin-left: 20px;
        float: left;
        width: 222px;
        font-size: 16px;
        font-family: PingFang SC;
        font-weight: 500;
        line-height: 22px;
        color: #333333;
        opacity: 1;
      }
    }
    .messageFour {
      margin-left: 24px;
      margin-top: 20px;
      width: 976px;
      height: 22px;
      // background: firebrick;
      .spanone {
        height: 22px;
        display: block;
        width: 64px;
        // height: 22px;
        font-size: 16px;
        font-family: PingFang SC;
        font-weight: 400;
        line-height: 22px;
        color: #666666;
        opacity: 1;
        float: left;
      }
      .spantwo {
        height: 22px;
        float: left;
        display: block;
        width: 223px;
        // height: 22px;
        font-size: 16px;
        font-family: PingFang SC;
        font-weight: 500;
        line-height: 22px;
        color: #333333;
        opacity: 1;
        margin-left: 20px;
      }
      .spanthree {
        height: 22px;
        margin-left: 39px;
        float: left;
        display: block;
        width: 64px;
        font-size: 16px;
        font-family: PingFang SC;
        font-weight: 400;
        line-height: 22px;
        color: #666666;
        opacity: 1;
      }
      .spanfour {
        height: 22px;
        margin-left: 39px;
        float: left;
        display: block;
        width: 152px;
        // height: 22px;
        font-size: 16px;
        font-family: PingFang SC;
        font-weight: 500;
        line-height: 22px;
        // color: #333333;
        // color: #ff0000;
        opacity: 1;
      }
      .green {
        color: #7cd601;
      }
      .bohui {
        color: #fa6e06;
      }
      .daoqi {
        color: #cccccc;
      }
      .dongjie {
        color: #db1818;
      }
      .daishenhe {
        color: #1883e3;
      }
    }
  }
  // 1111111
  .dia {
    .a_diaa_dia {
      display: flex;
      align-items: center;
      margin-top: 10px;
      justify-content: center;
      .first_dia {
        width: 128px;
        height: 22px;
        font-size: 16px;
        font-family: PingFang SC;
        font-weight: 400;
        line-height: 22px;
        color: #333333;
        opacity: 1;
        text-align: right;
      }
      .second_dia {
        width: 234px;
        height: 34px;
        background: #f4f7fe;
        border: 1px solid #cccccc;
        opacity: 1;
        border-radius: 6px;
        margin-left: 30px;
        box-sizing: border-box;
        padding-left: 14px;
      }
      .second_dia:focus {
        outline: none;
        border: 1px solid #0083e3;
      }
    }
  }
  .addhui {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 10px;
    .second-addhui {
      display: flex;
      .first {
        width: 124px;
        height: 42px;
        border: 1px solid #707070;
        opacity: 1;
        border-radius: 100px 0px 0px 100px;
        line-height: 42px;
        text-align: center;
      }
      .second {
        width: 124px;
        height: 42px;

        opacity: 1;
        border: 1px solid #707070;
        border-radius: 0px 100px 100px 0px;
        line-height: 42px;
        text-align: center;
      }
      .addcolor {
        background: #1883e3;

        color: #ffffff;
      }
    }
  }
  .compangdia {
    display: flex;
    margin-top: 20px;
    .first_compangdia {
      width: 80px;
      height: 22px;
      font-size: 16px;
      font-family: PingFang SC;
      font-weight: 400;
      line-height: 22px;
      color: #999999;
      opacity: 1;
      text-align: right;
    }
    .second_compangdia {
      height: 22px;
      font-size: 16px;
      font-family: PingFang SC;
      font-weight: 400;
      line-height: 22px;
      color: #333333;
      opacity: 1;
      margin-left: 30px;
    }
    .three_compangdia {
      width: 83px;
      height: 23px;
      border: 1px solid #0083e3;
      opacity: 1;
      border-radius: 6px;
      font-size: 12px;
      font-family: PingFang SC;
      font-weight: bold;
      line-height: 23px;
      color: #0083e3;
      opacity: 1;
      text-align: center;
      margin-left: 10px;
    }
  }
  .chanpin {
    width: 56px;
    height: 20px;
    font-size: 14px;
    font-family: PingFang SC;
    font-weight: bold;
    line-height: 20px;
    color: #333333;
    opacity: 1;
    margin-top: 10px;
  }
  .ender {
    display: flex;
    align-items: center;
    // justify-content: space-around;
    justify-content: center;
    .endera {
      width: 122px;
      height: 25px;
      border: 1px solid #0083e3;
      opacity: 1;
      border-radius: 6px;
      text-align: center;
      line-height: 25px;
      font-size: 12px;
      font-family: PingFang SC;
      font-weight: bold;
      margin-left: 15px;
      color: #0083e3;
      opacity: 1;
      margin-top: 18px;
    }
  }
  .tijiao {
    width: 115px;
    height: 34px;
    background: #0083e3;
    opacity: 1;
    border-radius: 6px;
    text-align: center;
    font-size: 18px;
    font-family: PingFang SC;
    font-weight: bold;
    line-height: 34px;
    color: #ffffff;
    opacity: 1;
    margin: 20px auto;
    cursor: pointer;
  }

  .data_first_dia {
    margin-left: 30px;
    width: 240px;
    background: #f4f7fe;
    border: 0px solid #cccccc;
    opacity: 1;
    border-radius: 6px 0px 0px 6px;
  }
  /deep/.el-tabs__nav-scroll {
    text-align: center;
    margin-left: 190px;
    border-bottom: none;
  }
  /deep/.el-tabs--card > .el-tabs__header .el-tabs__nav {
    // border: 1px solid #E4E7ED;
    border: 1px solid #707070;
    //  border-bottom: none;
    // border-radius: 4px 4px 0 0;
    /* box-sizing: border-box; */
    border-radius: 25px;
    width: 200px;
    height: 42px;
    opacity: 1;
    //  border-radius: 100px 0px 0px 100px;
  }
  /deep/.el-tabs--card > .el-tabs__header {
    // border-bottom: 1px solid #E4E7ED;
    border-bottom: 1px solid #ffffff;
    // border-bottom: none;
  }
  // 111111
  .user {
    margin-top: 20px;
    width: 1000px;
    height: 286px;
    background: #ffffff;
    box-shadow: 0px 0px 10px #bfdff7;
    opacity: 1;
    border-radius: 10px;
    overflow: hidden;
    .biaoti {
      margin-left: 24px;
      margin-top: 24px;
      width: 82px;
      height: 20px;
      font-size: 20px;
      font-family: AppleSystemUIFont;
      font-weight: 400;
      line-height: 23px;
      color: #000000;
      opacity: 1;
    }
    .userone {
      text-align: center;
      margin-left: 24px;
      margin-top: 18px;
      width: 976px;
      height: 22px;
      // background: #000;
      .spanone {
        height: 22px;
        width: 100px;
        // height: 22px;
        display: block;
        font-size: 16px;
        font-family: PingFang SC;
        font-weight: 400;
        line-height: 22px;
        color: #666666;
        opacity: 1;
        float: left;
      }
      .spantwo {
        height: 22px;
        width: 112px;
        display: block;
        float: left;
        font-size: 16px;
        font-family: PingFang SC;
        font-weight: 400;
        line-height: 22px;
        color: #666666;
        opacity: 1;
        margin-left: 4px;
      }
      .spanthree {
        height: 22px;
        width: 74px;
        // height: 22px;
        display: block;
        margin-left: 17px;
        float: left;
        font-size: 16px;
        font-family: PingFang SC;
        font-weight: 400;
        line-height: 22px;
        color: #666666;
        opacity: 1;
      }
      .spanfour {
        height: 22px;
        float: left;
        display: block;
        width: 74px;
        font-size: 16px;
        font-family: PingFang SC;
        font-weight: 400;
        line-height: 22px;
        color: #666666;
        opacity: 1;
        margin-left: 13px;
      }
      .spanfive {
        height: 22px;
        float: left;
        margin-left: 13px;
        width: 74px;
        display: block;
        font-size: 16px;
        font-family: PingFang SC;
        font-weight: 400;
        line-height: 22px;
        color: #666666;
        opacity: 1;
      }
      .spansix {
        height: 22px;
        float: left;
        margin-left: 10px;
        width: 74px;
        display: block;
        font-size: 16px;
        font-family: PingFang SC;
        font-weight: 400;
        line-height: 22px;
        color: #666666;
        opacity: 1;
      }
      .spanseven {
        height: 22px;
        float: left;
        margin-left: 10px;
        width: 74px;
        display: block;
        font-size: 16px;
        font-family: PingFang SC;
        font-weight: 400;
        line-height: 22px;
        color: #666666;
        opacity: 1;
      }
      .spaneight {
        height: 22px;
        display: block;
        float: left;
        margin-left: 13px;
        width: 116px;
        font-size: 16px;
        font-family: PingFang SC;
        font-weight: 400;
        line-height: 22px;
        color: #666666;
        opacity: 1;
      }
      .spannine {
        height: 22px;
        margin-left: 3px;
        float: left;
        width: 90px;
        display: block;
        font-size: 16px;
        font-family: PingFang SC;
        font-weight: 400;
        line-height: 22px;
        color: #666666;
        opacity: 1;
      }
      .spanzero {
        height: 22px;
        float: left;
        display: block;
        width: 90px;
        font-size: 16px;
        font-family: PingFang SC;
        font-weight: 400;
        line-height: 22px;
        color: #666666;
        opacity: 1;
      }
    }
    .usertwo {
      text-align: center;
      margin-left: 24px;
      margin-top: 20px;
      width: 976px;
      height: 22px;
      .spanone {
        height: 22px;
        width: 100px;
        // height: 22px;
        display: block;
        font-size: 16px;
        font-family: PingFang SC;
        font-weight: 500;
        line-height: 22px;
        color: #333333;
        opacity: 1;
        float: left;
      }
      .spantwo {
        height: 22px;
        width: 112px;
        display: block;
        float: left;
        font-size: 16px;
        font-family: PingFang SC;
        font-weight: 500;
        line-height: 22px;
        color: #333333;
        opacity: 1;
        margin-left: 4px;
      }
      .spanthree {
        height: 22px;
        width: 74px;
        // height: 22px;
        display: block;
        margin-left: 17px;
        float: left;
        font-size: 16px;
        font-family: PingFang SC;
        font-weight: 500;
        line-height: 22px;
        color: #333333;
        opacity: 1;
      }
      .spanfour {
        height: 22px;
        float: left;
        display: block;
        width: 74px;
        font-size: 16px;
        font-family: PingFang SC;
        font-weight: 500;
        line-height: 22px;
        color: #333333;
        opacity: 1;
        margin-left: 13px;
      }
      .spanfive {
        height: 22px;
        float: left;
        margin-left: 13px;
        width: 74px;
        display: block;
        font-size: 16px;
        font-family: PingFang SC;
        font-weight: 500;
        line-height: 22px;
        color: #333333;
        opacity: 1;
      }
      .spansix {
        height: 22px;
        float: left;
        margin-left: 10px;
        width: 74px;
        display: block;
        font-size: 16px;
        font-family: PingFang SC;
        font-weight: 500;
        line-height: 22px;
        color: #333333;
        opacity: 1;
      }
      .spanseven {
        height: 22px;
        float: left;
        margin-left: 10px;
        width: 74px;
        display: block;
        font-size: 16px;
        font-family: PingFang SC;
        font-weight: 500;
        line-height: 22px;
        color: #333333;
        opacity: 1;
      }
      .spaneight {
        height: 22px;
        display: block;
        float: left;
        margin-left: 13px;
        width: 116px;
        font-size: 16px;
        font-family: PingFang SC;
        font-weight: 500;
        line-height: 22px;
        color: #333333;
        opacity: 1;
      }
      .spannine {
        height: 22px;
        margin-left: 3px;
        float: left;
        width: 90px;
        display: block;
        font-size: 16px;
        font-family: PingFang SC;
        font-weight: 500;
        line-height: 22px;
        color: #333333;
        opacity: 1;
      }
      .spanzero {
        height: 22px;
        float: left;
        display: block;
        width: 90px;
        font-size: 16px;
        font-family: PingFang SC;
        font-weight: 500;
        line-height: 22px;
        color: #333333;
        opacity: 1;
      }
    }
  }
  .accessory {
    width: 1000px;
    height: 200px;
    background: #ffffff;
    box-shadow: 0px 0px 10px #bfdff7;
    opacity: 1;
    border-radius: 10px;
    margin-top: 20px;
    overflow: hidden;
    .biaoti {
      margin-left: 24px;
      margin-top: 24px;
      width: 82px;
      height: 20px;
      font-size: 20px;
      font-family: AppleSystemUIFont;
      font-weight: 400;
      line-height: 23px;
      color: #000000;
      opacity: 1;
    }
    .none {
      margin-left: 20px;
      margin-top: 20px;
      width: 976px;
      height: 22px;
      .one {
        height: 22px;
        width: 64px;
        display: block;
        float: left;
        font-size: 16px;
        font-family: PingFang SC;
        font-weight: 400;
        line-height: 22px;
        color: #666666;
        opacity: 1;
      }
      .two {
        height: 22px;
        float: left;
        width: 207px;
        display: block;
        font-size: 16px;
        font-family: PingFang SC;
        font-weight: 500;
        line-height: 22px;
        color: #333333;
        opacity: 1;
        margin-left: 20px;
      }
      .three {
        height: 22px;
        float: left;
        display: block;
        margin-left: 55px;
        width: 64px;
        font-size: 16px;
        font-family: PingFang SC;
        font-weight: 400;
        line-height: 22px;
        color: #666666;
        opacity: 1;
      }
      .four {
        height: 22px;
        width: 170px;
        font-size: 16px;
        font-family: PingFang SC;
        font-weight: 500;
        line-height: 22px;
        color: #333333;
        opacity: 1;
        margin-left: 22px;
        float: left;
      }
      .five {
        height: 22px;
        width: 64px;
        display: block;
        margin-left: 46px;
        float: left;
        font-size: 16px;
        font-family: PingFang SC;
        font-weight: 400;
        line-height: 22px;
        color: #666666;
        opacity: 1;
      }
      .six {
        height: 22px;
        width: 200px;
        display: block;
        margin-left: 20px;
        float: left;
        font-size: 16px;
        font-family: PingFang SC;
        font-weight: 500;
        line-height: 22px;
        color: #333333;
        opacity: 1;
      }
    }
    .ntwo {
      margin-left: 20px;
      margin-top: 20px;
      width: 976px;
      height: 22px;
      .one {
        height: 22px;
        width: 64px;
        display: block;
        float: left;
        font-size: 16px;
        font-family: PingFang SC;
        font-weight: 400;
        line-height: 22px;
        color: #666666;
        opacity: 1;
      }
      .two {
        height: 22px;
        float: left;
        width: 207px;
        display: block;
        font-size: 16px;
        font-family: PingFang SC;
        font-weight: 500;
        line-height: 22px;
        color: #333333;
        opacity: 1;
        margin-left: 20px;
      }
      .three {
        height: 22px;
        float: left;
        display: block;
        margin-left: 55px;
        width: 64px;
        font-size: 16px;
        font-family: PingFang SC;
        font-weight: 400;
        line-height: 22px;
        color: #666666;
        opacity: 1;
      }
      .four {
        height: 22px;
        width: 170px;
        font-size: 16px;
        font-family: PingFang SC;
        font-weight: 500;
        line-height: 22px;
        color: #333333;
        opacity: 1;
        margin-left: 22px;
        float: left;
      }
      .five {
        height: 22px;
        width: 64px;
        display: block;
        margin-left: 46px;
        float: left;
        font-size: 16px;
        font-family: PingFang SC;
        font-weight: 400;
        line-height: 22px;
        color: #666666;
        opacity: 1;
      }
      .six {
        height: 22px;
        width: 200px;
        display: block;
        margin-left: 20px;
        float: left;
        font-size: 16px;
        font-family: PingFang SC;
        font-weight: 500;
        line-height: 22px;
        color: #333333;
        opacity: 1;
      }
    }
  }
  .operating {
    width: 1000px;
    // height: 890px;
    background: #ffffff;
    box-shadow: 0px 0px 10px #bfdff7;
    opacity: 1;
    border-radius: 10px;
    margin-top: 20px;
    overflow: hidden;
    .biaoti {
      margin-left: 24px;
      margin-top: 24px;
      width: 82px;
      height: 20px;
      font-size: 20px;
      font-family: AppleSystemUIFont;
      font-weight: 400;
      line-height: 23px;
      color: #000000;
      opacity: 1;
    }
  }
  /deep/.el-dialog__header {
    background-color: #1883e3;
  }
  /deep/.el-dialog__title {
    width: 64px;
    height: 22px;
    font-size: 16px;
    font-family: PingFang SC;
    font-weight: 400;
    line-height: 22px;
    color: #ffffff;
    opacity: 1;
  }
  .tijiao {
    width: 115px;
    height: 34px;
    background: #0083e3;
    opacity: 1;
    border-radius: 6px;
    text-align: center;
    font-size: 18px;
    font-family: PingFang SC;
    font-weight: bold;
    line-height: 34px;
    color: #ffffff;
    opacity: 1;
    margin: 20px auto;
    cursor: pointer;
  }
  .daa {
    margin: 0 auto;
    width: 400px;
    .endera {
      width: 122px;
      height: 25px;
      border: 1px solid #0083e3;
      opacity: 1;
      border-radius: 6px;
      text-align: center;
      line-height: 25px;
      font-size: 12px;
      font-family: PingFang SC;
      font-weight: bold;
      // margin-left: 15px;
      color: #0083e3;
      opacity: 1;
      margin-top: 18px;
      margin: 0 auto;
    }
  }
  // .da{
  //   // margin: 0 auto;
  //   margin-left:100px;
  //   width: 800px;
  //   //   height: 300px;
  //   //  background: red;
  // }
  .daaa {
    margin-left: 100px;
    width: 800px;
  }

  .input {
    width: 850px;
    height: 50px;

    p {
      float: left;
      text-align: center;
      // width: 112px;
      // height: 22px;
      // font-size: 16px;
      // font-family: PingFang SC;
      // font-weight: 400;
      // line-height: 22px;
      color: #666666;
      // opacity: 1;
      margin-left: 10px;
    }
  }
  //权益弹框
  .div {
    width: 850px;
    height: 50px;
    //   height: 500px;
    //   background: #000;
    p {
      float: left;
      text-align: center;
      // width: 112px;
      // height: 22px;
      // font-size: 16px;
      // font-family: PingFang SC;
      // font-weight: 400;
      // line-height: 22px;
      color: #666666;
      // opacity: 1;
      margin-left: 10px;
    }
  }
  .inputa {
    width: 850px;
    height: 50px;
    p {
      float: left;
      text-align: center;
      // width: 112px;
      // height: 22px;
      // font-size: 16px;
      // font-family: PingFang SC;
      // font-weight: 400;
      // line-height: 22px;
      color: #666666;
      // opacity: 1;
      margin-left: 10px;
    }
  }
  // /deep/ .el-button{
  //   float:right;
  // }
}
</style>
